@import '../scss/globals.scss';
@import '../scss/mix-ins.scss';

.main {
    font-family: $body-font;
    height: auto;
    color: black;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    &-link {
        text-decoration: none;
        color: black;
         &:hover {
            color: black;
            text-decoration: underline;
        }
    }
}

h1 {
    color: black;
    font-style: italic;
    font-family: $header-font;
    font-size: 80px;
    margin-bottom: 0;
}

.links {
    &-page {
        font-size: 30px;
        color: black;
    }
    &-main {
        text-decoration: none;
        color: black;
         &:hover {
            color: black;
            text-decoration: underline;
        }
    }
    &-contact {
        a {
            display: inline-block;
            text-decoration: none;
            color: black;
            padding: 5px;
            font-size: 60px;
            @include respond-to($small-screen) {
                display: none;
            }
        }
    }
}

.intro {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    min-height: 100vh;

    @include respond-to($medium-screen) {
        min-height: 110vh;
    }

    @include respond-to($small-screen) {
        min-height: 85vh;
        max-width: 100vw;
    }

    .line {
        height: .1em;
        width: 30vw;
        background: black;
        margin: 10px auto;
    }

    h2 {
        margin-bottom: 1em;
    }
} 


.about {
    min-height: 80vh;
    padding-top: 10vh;
    span {
        font-weight: bolder;
    }
    p {
        font-size: 14px;
    }
    .hide-img {
        display: none;
    }
    .skills {
        text-align: center;
        ul {
            margin-top: 3%;
            list-style-type: none;
            text-align: left;
            width: 100%;
            display: grid;
            grid-template-rows: auto;
            grid-template-columns: 30% 30% 30%;
            grid-auto-flow: row;
            column-gap: 5%;
            @include respond-to($medium-screen) {
                grid-template-columns: 40% 40%;
                column-gap: 10%;
                text-align: center;
            }
            @include respond-to($small-screen) {
                grid-template-columns: 100%;    
            }
        }
    }
    .ab {
        @include respond-to($small-screen) {
            .my-img {
                display: none;
            }
            .hide-img {
                display: inline-block;
                height: 20vh;
            }
            h1 {
                font-size: 60px;
            }
            h4 {
                font-size: 20px;
            }
            .about-head {
                text-align: center;
            }
            div {
                text-align: center;
            }
        }
    }
    
}

.work {
    margin-bottom: 100px;
    min-height: 80vh;
    padding-top: 10vh;
    
    .project {
        background-color: $background-color;
        // @include respond-to($small-screen) {
        //     display: flex;
        //     flex-direction: column-reverse;
        // }
        hr {
            margin-top: 0;
        }
        &-info {
            padding: 5%;
            margin: 0 auto;
        }
    }
}

.images{
    @include respond-to($small-screen) {
        max-height: 50vh;
        max-width: 80vw;
        margin: 0 auto;
    }
    margin-top: 5%;
}

.carousel {

    .carousel-item {
        // max-height: 55vh;
        // min-height: 55vh;
        height: 55vh;
        text-align: center;
        img {
            max-height: 55vh;
            position: absolute;
            margin: auto;
            top: 0;
            bottom: 0;
            left: 0;
            right: 0;
        }
        @include respond-to($small-screen) {
            max-height: 40vh;
        }
    }
    .carousel-control-prev-icon {
        background: url(../_Assets/img/chevron-left-solid.svg);
        background-repeat: no-repeat;
    }
    .carousel-control-next-icon {
        background: url(../_Assets/img/chevron-right-solid.svg);
        background-repeat: no-repeat;
    }

}
