@import './scss/globals.scss';

html {
  scroll-behavior: smooth;
}

body {
  margin: 0;
  background-color: $background-color;
  max-width: 100vw; 
  display: grid;
  font-family: -apple-system, BlinkMacSystemFont, $body-font;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

h1 {
  font-family: $header-font;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}
