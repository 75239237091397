@import '../scss/globals.scss';
@import '../scss/mix-ins.scss';

.navbar {
    // width: 100%;
    // display: flex;
    // flex-direction: row;
    // justify-content: space-between;
    background-color: $background-color;
    // position: fixed;
    top: 0px;
    transition: top 0.3s;
    @include respond-to($small-screen) {
        top: 0px;
        transition: none;
    }
    font-family: $header-font;
    &-brand {
        color: black;
        &:hover {
            color: gray;
        }
    }
    a {
        color: black;
        font-family: $header-font;
        & :hover {
            color: gray ;
        }
    }
}

.navbar-nav {
    display: flex;
    flex-direction: row;
    a {
        &:hover {
            color: gray;
        }
    }
}

.nav-item {
    padding-right: 1em;
}

.top-icons {
    display: inline;
    @include respond-to($small-screen) {
        display: none;
    }
}


footer {

    font-size: 40px;
    width: 100vw;
    height: 10vh;
    background: $background-color;
    
    a {
        color: black;
    }



    .btm-icons {
        display: none;
        a {
            color: black;
        }
        @include respond-to($small-screen) {
            display: block;
        }
    }
}